import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IModuleInitialData, IModulePreInitialData, IServiceNameMapping, IUserSettingsInfo, UserSettingsSection } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import { ApiServiceBase } from '@profis-engineering/pe-ui-common/services/api.common';
import { BrowserServiceBase } from '@profis-engineering/pe-ui-common/services/browser.common';
import { ChangesServiceBase } from '@profis-engineering/pe-ui-common/services/changes.common';
import { CommonCodeListServiceBase } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { CommonTrackingServiceBase } from '@profis-engineering/pe-ui-common/services/common-tracking.common';
import { DocumentServiceBase } from '@profis-engineering/pe-ui-common/services/document.common';
import { FavoritesServiceBase } from '@profis-engineering/pe-ui-common/services/favorites.common';
import { FeatureVisibilityServiceBase } from '@profis-engineering/pe-ui-common/services/feature-visibility.common';
import { FeaturesVisibilityInfoServiceBase } from '@profis-engineering/pe-ui-common/services/features-visibility-info.common';
import { ImportServiceBase } from '@profis-engineering/pe-ui-common/services/import.common';
import { LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { MathServiceBase } from '@profis-engineering/pe-ui-common/services/math.common';
import { MenuServiceBase } from '@profis-engineering/pe-ui-common/services/menu.common';
import { ModalServiceBase } from '@profis-engineering/pe-ui-common/services/modal.common';
import { NumberServiceBase } from '@profis-engineering/pe-ui-common/services/number.common';
import { RegionOrderServiceBase } from '@profis-engineering/pe-ui-common/services/region-order.common';
import { ReportTemplateServiceBase } from '@profis-engineering/pe-ui-common/services/report-template.common';
import { RoutingServiceBase } from '@profis-engineering/pe-ui-common/services/routing.common';
import { TooltipServiceBase } from '@profis-engineering/pe-ui-common/services/tooltip.common';
import { TourServiceBase } from '@profis-engineering/pe-ui-common/services/tour.common';
import { UnitServiceBase } from '@profis-engineering/pe-ui-common/services/unit.common';
import { UserSettingsServiceBase } from '@profis-engineering/pe-ui-common/services/user-settings.common';
import { UserServiceBase } from '@profis-engineering/pe-ui-common/services/user.common';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { BrowserService } from '../../services/browser.service';
import { ChangesService } from '../../services/changes.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { CommonTrackingService } from '../../services/common-tracking.service';
import { CoreApiService, CoreApiType } from '../../services/core-api.service';
import { DataService } from '../../services/data.service';
import { DesignTemplateService, DesignTemplateServiceBaseInternal } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { DotnetService } from '../../services/dotnet.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeatureFlag, FeatureVisibilityService } from '../../services/features-visibility.service';
import { ImportService } from '../../services/import.service';
import { InitialDataService } from '../../services/initial-data.service';
import { LocalizationService } from '../../services/localization.service';
import { MathService } from '../../services/math.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { RegionOrderService } from '../../services/region-order.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { RoutingService } from '../../services/routing.service';
import { SharedEnvironmentData, SharedEnvironmentService } from '../../services/shared-environment.service';
import { TooltipService } from '../../services/tooltip.service';
import { TourService } from '../../services/tour.service';
import { UnitService } from '../../services/unit.service';
import { UserSettings, UserSettingsMasonryRnf, UserSettingsService } from '../../services/user-settings.service';
import { InternalDesign, UserService } from '../../services/user.service';

@Component({
    template: '',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class UiInitComponent implements OnInit {
    @Input()
    public sharedEnvironmentData!: SharedEnvironmentData;

    @Input()
    public userSettingsServiceBase!: UserSettingsServiceBase<UserSettings>;

    @Input()
    public localizationServiceBase!: LocalizationServiceBase;

    @Input()
    public apiServiceBase!: ApiServiceBase;

    @Input()
    public commonCodeListServiceBase!: CommonCodeListServiceBase;

    @Input()
    public commonTrackingServiceBase!: CommonTrackingServiceBase;

    @Input()
    public designTemplateServiceBase!: DesignTemplateServiceBaseInternal;

    @Input()
    public documentServiceBase!: DocumentServiceBase;

    @Input()
    public favoritesServiceBase!: FavoritesServiceBase;

    @Input()
    public modalServiceBase!: ModalServiceBase;

    @Input()
    public numberServiceBase!: NumberServiceBase;

    @Input()
    public routingServiceBase!: RoutingServiceBase;

    @Input()
    public tourServiceBase!: TourServiceBase;

    @Input()
    public userServiceBase!: UserServiceBase<InternalDesign>;

    @Input()
    public unitServiceBase!: UnitServiceBase;

    @Input()
    public menuServiceBase!: MenuServiceBase;

    @Input()
    public importServiceBase!: ImportServiceBase;

    @Input()
    public browserServiceBase!: BrowserServiceBase;

    @Input()
    public changesServiceBase!: ChangesServiceBase;

    @Input()
    public featuresVisibilityInfoServiceBase!: FeaturesVisibilityInfoServiceBase;

    @Input()
    public mathServiceBase!: MathServiceBase;

    @Input()
    public tooltipServiceBase!: TooltipServiceBase;

    @Input()
    public featureVisibilityServiceBase!: FeatureVisibilityServiceBase<FeatureFlag>;

    @Input()
    public reportTemplateServiceBase!: ReportTemplateServiceBase;

    @Input()
    public regionOrderServiceBase!: RegionOrderServiceBase;

    @Output()
    public preInit = new EventEmitter<IModulePreInitialData | (() => IModulePreInitialData | Promise<IModulePreInitialData>)>();

    @Output()
    public init = new EventEmitter<IModuleInitialData>();

    constructor(
        private readonly initialDataService: InitialDataService,
        private readonly sharedEnvironmentService: SharedEnvironmentService,
        private readonly userSettingsService: UserSettingsService,
        private readonly localizationService: LocalizationService,
        private readonly apiService: ApiService,
        private readonly commonCodeListService: CommonCodeListService,
        private readonly commonTrackingService: CommonTrackingService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly documentService: DocumentService,
        private readonly favoritesService: FavoritesService,
        private readonly modalService: ModalService,
        private readonly numberService: NumberService,
        private readonly routingService: RoutingService,
        private readonly tourService: TourService,
        private readonly userService: UserService,
        private readonly dataService: DataService,
        private readonly unitService: UnitService,
        private readonly menuService: MenuService,
        private readonly importService: ImportService,
        private readonly browserService: BrowserService,
        private readonly changesService: ChangesService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly mathService: MathService,
        private readonly tooltipService: TooltipService,
        private readonly dotnetService: DotnetService,
        private readonly coreApiService: CoreApiService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly reportTemplateService: ReportTemplateService,
        private readonly regionOrderService: RegionOrderService,
        private readonly applicationProviderService: ApplicationProviderService
    ) { }

    public ngOnInit(): void {
        this.preInit.emit(() => {
            const preInitData = this.getPreInitialData();

            // after all the initial data is loaded (pe-ui and masonry-reinforcement-ui) init services in order and then notify pe-ui with init to render
            preInitData.initialDataLoadedEvent = async () => {
                // we need feature service set to check feature flag status
                this.featureVisibilityService.setBaseService(this.featureVisibilityServiceBase);

                // skip init and preInit emit if module is disabled
                if (typeof environment.enabled == 'boolean') {
                    // we have environment setting so we ignore LD
                    if (!environment.enabled) {
                        return;
                    }
                }
                else {
                    // no environment setting so we check LD
                    if (!this.featureVisibilityService.isFeatureEnabled('MasonryReinforcement_Enabled')) {
                        return;
                    }
                }

                this.preInitServices();
                await this.loadData();
                this.initServices();

                // TODO - update event should be removed once necessary fixes (module sending localization extension for initial data translation)
                // in pe-ui are done
                this.localizationService.localizationChange.subscribe(this.applicationProviderService.updateTranslations.bind(this.applicationProviderService));

                this.init.emit(this.initialDataService.getInitialData());
            };

            return preInitData;
        });
    }

    /** Called before data is loaded */
    private preInitServices() {
        this.connectBaseServices();
        if (environment.webassembly) {
            this.coreApiService.type = CoreApiType.webassembly;
        }
    }

    /** Called after data is loaded */
    private initServices() {
        this.userSettingsService.init();
        this.menuService.init();
        this.sharedEnvironmentService.init(this.sharedEnvironmentData);
    }

    private async loadData() {
        const loaded = await Promise.allSettled([
            this.dataService.loadData(),
            this.localizationService.loadTranslations(),
        ]);

        for (const result of loaded) {
            if (result.status === 'rejected') {
                throw result.reason;
            }
        }
    }

    private connectBaseServices(): void {
        this.localizationService.setBaseService(this.localizationServiceBase);
        this.apiService.setBaseService(this.apiServiceBase);
        this.commonCodeListService.setBaseService(this.commonCodeListServiceBase);
        this.commonTrackingService.setBaseService(this.commonTrackingServiceBase);
        this.designTemplateService.setBaseService(this.designTemplateServiceBase);
        this.documentService.setBaseService(this.documentServiceBase);
        this.favoritesService.setBaseService(this.favoritesServiceBase);
        this.modalService.setBaseService(this.modalServiceBase);
        this.numberService.setBaseService(this.numberServiceBase);
        this.routingService.setBaseService(this.routingServiceBase);
        this.tourService.setBaseService(this.tourServiceBase);
        this.userService.setBaseService(this.userServiceBase);
        this.userSettingsService.setBaseService(this.userSettingsServiceBase);
        this.unitService.setBaseService(this.unitServiceBase);
        this.menuService.setBaseService(this.menuServiceBase);
        this.importService.setBaseService(this.importServiceBase);
        this.browserService.setBaseService(this.browserServiceBase);
        this.changesService.setBaseService(this.changesServiceBase);
        this.featuresVisibilityInfoService.setBaseService(this.featuresVisibilityInfoServiceBase);
        this.mathService.setBaseService(this.mathServiceBase);
        this.tooltipService.setBaseService(this.tooltipServiceBase);
        this.reportTemplateService.setBaseService(this.reportTemplateServiceBase);
        this.regionOrderService.setBaseService(this.regionOrderServiceBase);
    }

    private getPreInitialData(): IModulePreInitialData {
        // will be called even if module is disabled
        return {
            // Common
            authenticationRequiredServices: this.getAuthenticationRequiredServices(),
            serviceNameMapping: this.getServiceNameMapping(),
            userSettingsInfo: this.getUserSettingsInfo()
        };
    }

    private getAuthenticationRequiredServices() {
        // will be called even if module is disabled
        return [
            environment.designServiceUrl,
            environment.calculationServiceUrl,
            environment.reportServiceUrl,
            environment.htmlReportServiceUrl
        ];
    }

    private getServiceNameMapping(): IServiceNameMapping {
        // will be called even if module is disabled
        const mapping: IServiceNameMapping = {};

        mapping[environment.designServiceUrl] = 'masonry-reinforcement-design-service';
        mapping[environment.calculationServiceUrl] = 'masonry-reinforcement-calculation-service';
        mapping[environment.reportServiceUrl] = 'masonry-reinforcement-report-service';
        mapping[environment.htmlReportServiceUrl] = 'pe-html-report';

        return mapping;
    }

    /** user settings definition */
    private getUserSettingsInfo(): IUserSettingsInfo {
        // will be called even if module is disabled
        return {
            userSettingsKey: 'masonryRnf',
            userSettings: new UserSettingsMasonryRnf(
                this.dataService,
                this.userSettingsService
            ) as unknown as UserSettingsSection
        };
    }
}
