import { Injectable } from '@angular/core';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import {
    DocumentAccessMode, DocumentServiceBase, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    private baseService!: DocumentServiceBase;

    public get draftsProject() {
        return this.baseService.draftsProject;
    }


    public setBaseService(baseService: DocumentServiceBase): void {
        this.baseService = baseService;
    }


    public findDesignById(designId: string): IDesignListItem {
        return this.baseService.findDesignById(designId);
    }

    public findProjectById(projectId: string): Project {
        return this.baseService.findProjectById(projectId);
    }

    public addDesignCommon(projectId: string, design: Design, canGenerateUniqueName: boolean, ignoreConflict: boolean): Promise<IDesignListItem> {
        return this.baseService.addDesignCommon(projectId, design, canGenerateUniqueName, ignoreConflict);
    }

    public updateDesignWithNewContentCommon(design: Design, displayDesign: unknown, unlock: boolean, exclusiveLock: boolean, documentAccessMode?: DocumentAccessMode): Promise<void> {
        return this.baseService.updateDesignWithNewContentCommon(design, displayDesign, unlock, exclusiveLock, documentAccessMode);
    }

    public updateDesignThumbnailImage(designId: string, thumbnailContent: string, respond: boolean): Promise<void> {
        return this.baseService.updateDesignThumbnailImage(designId, thumbnailContent, respond);
    }

    public publish(id: string): Promise<void> {
        return this.baseService.publish(id);
    }

    public openDesignExclusive<TProjectDesign>(design: IBaseDesign, adjustContent?: (content: TProjectDesign, designName: string, projectName: string) => TProjectDesign): Promise<TProjectDesign> {
        return this.baseService.openDesignExclusive<TProjectDesign>(design, adjustContent);
    }

    public createUniqueName(oldName: string, usedNames: string[]): string {
        return this.baseService.createUniqueName(oldName, usedNames);
    }
}
