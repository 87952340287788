import { WritableKeys } from 'ts-essentials';

import { Injectable } from '@angular/core';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import {
    Design, IDesignStateBase, IProperty, StateChange
} from '@profis-engineering/pe-ui-common/entities/design';
import {
    DisplayDesignType, IDisplayDesign
} from '@profis-engineering/pe-ui-common/entities/display-design';
import { TrackChanges } from '@profis-engineering/pe-ui-common/entities/track-changes';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import { SpecialRegion } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    ApiOptions, CancellationTokenSource
} from '@profis-engineering/pe-ui-common/services/api.common';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import {
    CantOpenDesignBecauseLockedByOtherUser, IDesignListItem
} from '@profis-engineering/pe-ui-common/services/document.common';
import { LoggerServiceBase } from '@profis-engineering/pe-ui-common/services/logger.common';

import { getSpriteAsIconStyle } from '../sprites';
import { ChangesService } from './changes.service';
import { CommonCodeListService } from './common-code-list.service';
import { CoreApiService } from './core-api.service';
import { ApiAppPropertyId, DataService, PropertyDetail, Region } from './data.service';
import { DesignTemplateService } from './design-template.service';
import { DocumentService } from './document.service';
import { GuidService } from './guid.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { NumberService } from './number.service';
import { TrackingDetails, TrackingService } from './tracking.service';
import { UserSettingsService } from './user-settings.service';
import { InternalDesign } from './user.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { ReportType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { DefaultDateFormat } from '@profis-engineering/pe-ui-common/services/localization.common';

export interface Properties {
    unitLength: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;

    /** null = Default, 0 = Custom, other = Specific or Default if not found */
    reportTemplateId: number | null;
    reportTypeId: number | null;
    reportFirstPage: number | null;
    reportLanguageId: number | null;
    reportPaperSizeId: number | null;
    reportCompanyName: string | null;
    reportAddress: string | null;
    reportContactPerson: string | null;
    reportPhoneNumber: string | null;
    reportEmail: string | null;
    reportNotes: string | null;

    wallThickness: number;
    wallWidth: number;
    wallHeight: number;

    compressiveStrength: number;
    specificWeight: number;
    shearStrengthDiagonalCrack: number;
    shearStrengthStairSteppedCrack: number;
    knowledgeLevel: number;
    partialSafetyFactor: number;

    confidenceFactorCalculated: number;
    compressiveStrengthCalculated: number;
    shearStrengthDiagonalCrackCalculated: number;
    shearStrengthStairSteppedCrackCalculated: number;

    axialCompressiveLoad: number;
    inPlaneBendingMoment: number;
    outOfPlaneBendingMoment: number;
    shearLoad: number;

    plasterThickness: number;
    numberOfAnchors: number;
    plasterSpecificWeight: number;
    plasterCompressiveStrength: number;

    anchorDiameterCalculated: number | PropertyValueResult;
    embedmentDepthCalculated: number | PropertyValueResult;
    extendingProtrudingLengthCalculated: number | PropertyValueResult;
    outsideBendRadiusCalculated: number | PropertyValueResult;
    rebarStrainghtLengthCalculated: number | PropertyValueResult;
    horizontalSpacingCalculated: number | PropertyValueResult;
    verticalSpacingCalculated: number | PropertyValueResult;
    anchorMinEdgeDistanceCalculated: number | PropertyValueResult;
    shiftBetweenAnchorsCalculated: number | PropertyValueResult;
    numberOfAnchorsRowsCalculated: number | PropertyValueResult;
    numberOfAnchorsColumnsCalculated: number | PropertyValueResult;
    totalNumberOfAnchorsCalculated: number | PropertyValueResult;
    numberOfAnchorsPerAreaCalculated: number | PropertyValueResult;
    plasterElaticModulusCalculated: number | PropertyValueResult;
}

export interface PropertyValueResult {
    propertyValueType: string;
    value: string;
}

export type PropertyId = keyof Properties;
export type WritableProperties = Pick<Properties, WritableKeys<Properties>>;
export type WritablePropertyId = keyof WritableProperties;
export type PropertyDetails = Partial<Record<keyof Properties, PropertyDetail>>;

export interface DesignDetailsData {
    regionId: number;
    designTypeId: DesignTypeId;
    properties: Properties;
    propertyDetails: PropertyDetails;
}

export interface DesignDetails {
    properties: Properties;
    propertyDetails: PropertyDetails;

    regionId: number;
    designTypeId: DesignTypeId;

    /** -- DO NOT USE THIS -- Only used for design file (import and export). You probably want data from .properties property. */
    projectDesign: ProjectDesign;

    isTemplate: boolean;
    /** is undefined for template - check isTemplate property */
    designId: string | undefined;
    /** is undefined for template - check isTemplate property */
    designName: string | undefined;
    /** is undefined for template - check isTemplate property */
    projectId: string | undefined;
    /** is undefined for template - check isTemplate property */
    projectName: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateId: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateName: string | undefined;
    /** is undefined for design - check isTemplate property - is null when template project is root */
    templateProjectId: string | undefined;
    /** is undefined for design - check isTemplate property */
    templateProjectName: string | undefined;

    region: Region;
    commonRegion: CommonRegion;
    designType: DesignType;

    calculationResult?: CalculationResult;
}

export interface CreateDesignOrDesignTemplateOptions extends Omit<CreateDesignOptions & CreateDesignTemplateOptions, 'designName' | 'projectId' | 'templateName' | 'templateProjectId'> {
    designName: string | undefined;
    projectId: string | undefined;

    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;
}

export type DesignTypeMainId = 130;
export type DesignTypeId = DesignTypeMainId;

export type DesignTypeMainName = 'main';
export type DesignTypeName = DesignTypeMainName;

export type DesignTypeMain = typeof designTypes['main'];
export type DesignType = DesignTypeMain;

export const designTypes = {
    main: {
        id: 130,
        name: 'main',
        nameKey: 'MasonryRnf.DesignList.DesignType'
    }
} satisfies Record<DesignTypeName, {
    id: DesignTypeId;
    name: DesignTypeName;
    nameKey: string;
}>;

export const designTypesById: Record<DesignTypeId, DesignType> = Object.fromEntries(Object.entries(designTypes).map(([, designTypeDetails]) => [designTypeDetails.id, designTypeDetails])) as unknown as Record<DesignTypeId, DesignType>;

export interface ProjectDesign {
    designTypeId: DesignTypeId;
    regionId: number;
    designStandardId: number;
}

export enum ConfirmationType {
    Confirmation = 0,
    Information = 1,
}

// TODO FILIP: create some base inertfaces so we don't copy paste properties

export interface CreateDesignOptions {
    projectId: string;
    designName: string;
    designTypeId: DesignTypeId;
    regionId: number;

    unitLength: UnitType;
    unitStress: UnitType;

    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;
}

export interface CreateDesignFromProjectDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designName: string;
}

export interface DesignServiceCreateDesignOptions {
    designTypeId: DesignTypeId;
    regionId: number;

    unitLength: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;
}

export interface DocumentServiceCreateDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designName: string;
    regionId: number;
    designTypeId: DesignTypeId;
}

export interface LocalizationOptions {
    language: string;
    numberDecimalSeparator: string;
    numberGroupSeparator: string;
    globalRegionShortDatePattern?: string;
}

export interface TemplateOptions {
    headerText?: string;
    footerText?: string;
    userLogoId?: number;
    company?: string;
    address?: string;
    phone?: string;
    fax?: string;
    specifier?: string;
    email?: string;
    excludeCompanyDetails: boolean;
}

export enum CalculationStatus {
    OK = 1,
    OutOfScope = 2,
    KernelError = 3,
    UnhandledError = 4,
}

export interface CalculationResult {
    calculationStatus: CalculationStatus;
    isDesignValid: boolean;
    scopeCheckResults?: ScopeCheckResults;
    kernelResult?: KernelResult;
}

export interface ScopeCheckResults {
    failedScopeChecks?: ScopeCheckResult[];
}

export interface KernelResult {
    kernelResultCapacityOfUnreinforcedWall: KernelResultCapacityOfUnreinforcedWall;
    kernelResultMechanicalParametersRetrofittedWall: KernelResultMechanicalParametersRetrofittedWall;
    kernelResultsCapacityOfRetrofittedWall: KernelResultsCapacityOfRetrofittedWall;
    kernelResultsIncrementOfCapacityOfRetrofittedWall: KernelResultsIncrementOfCapacityOfRetrofittedWall;
    kernelResultBillOfMaterial: KernelResultBillOfMaterial;
    anchorDiameterCalculated: number;
    embedmentDepthCalculated: number;
    extendingProtrudingLengthCalculated: number;
    outsideBendRadiusCalculated: number;
    rebarStrainghtLengthCalculated: number;
    horizontalSpacingCalculated: number;
    verticalSpacingCalculated: number;
    anchorMinEdgeDistanceCalculated: number;
    shiftBetweenAnchorsCalculated: number;
    numberOfAnchorsColumnsCalculated: number;
    numberOfAnchorsRowsCalculated: number;
    numberOfAnchorsPerAreaCalculated: number;
    totalNumberOfAnchorsCalculated: number;
    plasterElaticModulusCalculated: number;
    compressiveStrengthCalculated: number;
    confidenceFactorCalculated: number;
    shearStrengthDiagonalCrackCalculated: number;
    shearStrengthStairSteppedCrackCalculated: number;
}

export interface ScopeCheckResult {
    isValid: boolean;
    severity: ScopeCheckSeverity;
    message: TranslationFormat;
}

export interface ConstantParameter extends TranslationParameter {
    value: string;
}

export interface NumericalParameter extends TranslationParameter {
    additionalPrecision?: number;
    unitGroup: UnitGroup;
    value?: number;
}

export interface TemplateParameter extends TranslationParameter {
    value: TranslationFormat;
}

export interface TranslatableParameter extends TranslationParameter {
    value: string;
}

export interface TranslationFormat {
    template?: string;
    templateFormat?: TranslationFormat;
    translationParameters: TranslationParameter[];
}

export interface TranslationParameter {
    name: string;
    parameterType: TranslationParameterType;
}

export const enum TranslationParameterType {
    None = 0,
    Numerical = 1,
    Translatable = 2,
    Constant = 3,
    Template = 4
}

export enum ScopeCheckSeverity {
    Info,
    Error
}

export interface CreateDesignDetailsOptions {
    projectDesign: ProjectDesign;
    designDetailsData: DesignDetailsData;
    calculationResult?: CalculationResult;

    projectId?: string;
    projectName?: string;
    designId?: string;
    designName?: string;

    /** is null when template project is root */
    templateProjectId?: string;
    templateProjectName?: string;
    templateId?: string;
    templateName?: string;
}

export interface UpdateDesignOptions {
    projectId: string;
    designId: string;
    designName: string;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface UpdateDesignOrDesignTemplateOptions {
    designTypeId: DesignTypeId;
    designId: string | undefined;
    designName: string | undefined;
    projectId: string | undefined;

    templateId: string | undefined;
    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface UpdateDesignFromProjectDesignOptions {
    designId: string;
    designName: string;
    projectId: string;
    projectDesign: ProjectDesign;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface DesignServiceUpdateDesignOptions {
    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];
}

export interface ImageUploadResponse {
    imageKey: string;
    position: number;
}

export interface UploadedPicture {
    content: string;
    position: number;
}

export interface UploadCustomPicture {
    imagesData: UploadedPicture[];
}

export interface CustomPictureData {
    imageKeys: string[];
}


export interface DocumentServiceUpdateDesignOptions {
    projectDesign: ProjectDesign;
    projectId: string;
    designId: string;
    designName: string;
    regionId: number;
    designTypeId: DesignTypeId;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface TrackOnDesignChangeOptions {
    trackingDetails: TrackingDetails;
    designDetails: DesignDetails;

    /**
     * - true: call tracking immediately and return a Promise that awaits the tracking request
     * - false: call tracking in the background with debounce and return a Promise that does NOT await the tracking request
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceUpdateDesignOrDesignTemplateOptions {
    designId: string | undefined;
    designName: string | undefined;
    projectId: string | undefined;

    templateId: string | undefined;
    templateName: string | undefined;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    regionId: number;
    designTypeId: DesignTypeId;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface OpenDesignOptions {
    designId: string;
}

export interface OpenDesignTemplateOptions {
    designTemplateId: string;
}

export interface UpdateDesignImageOptions {
    designId: string;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface UpdateDesignImageOrTemplateDesignImageOptions {
    designId: string | undefined;
    templateId: string | undefined;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceUpdateDesignTemplateOptions {
    templateId: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;
    templateName: string;

    designTypeId: DesignTypeId;
    regionId: number;
    projectDesign: ProjectDesign;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface DocumentServiceCreateDesignTemplateOptions {
    projectDesign: ProjectDesign;
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;
    designTypeId: DesignTypeId;
    regionId: number;
}

export interface CreateDesignTemplateOptions {
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    designTypeId: DesignTypeId;
    regionId: number;

    unitLength: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;
}

export interface UpdateDesignTemplateOptions {
    templateId: string;
    templateName: string;
    /** is null when template project is root */
    templateProjectId: string | undefined;

    projectDesign: ProjectDesign;
    properties: PropertyIdValue[];

    trackingDetails: TrackingDetails;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise that does NOT await the document service request
     * */
    immediateRequest: boolean;
}

export interface UpdateDesignTemplateImageOptions {
    templateId: string;
    base64Image: string;

    /**
     * - true: call document service immediately and return a Promise that awaits the document service request
     * - false: call document service in the background with debounce and return a Promise
     * */
    immediateRequest: boolean;
}

export interface CreateDesignFromTemplateOptions {
    templateId: string;
    projectId: string | undefined;
    designName: string | undefined;
}

export interface CloseDesignOptions {
    designTypeId: DesignTypeId;
    designId: string;
    regionDisplayKey: string;
}

export interface CloseDesignTemplateOptions {
    designTypeId: DesignTypeId;
    templateId: string;
    regionDisplayKey: string;
}

export type PropertyIdValueType = {
    [P in WritablePropertyId]: {
        propertyId: P;
        propertyValue: WritableProperties[P];
    };
};
export type PropertyIdValue = {
    [P in WritablePropertyId]: {
        propertyId: P;
        propertyValue: WritableProperties[P];
    };
}[WritablePropertyId];

export interface ApiDesignCreateRequest {
    designTypeId: DesignTypeId;
    regionId: number;

    unitLength: UnitType;
    unitStress: UnitType;
    unitForce: UnitType;
    unitMoment: UnitType;
    unitArea: UnitType;
    unitItemsPerArea: UnitType;
    unitVolume: UnitType;
    unitSpecificWeight: UnitType;
}

export interface DesignUpdateRequest {
    projectDesign: ProjectDesign;
    properties: ApiUpdateDesignProperty[];
}

export interface ApiDesignUpdateRequest {
    projectDesign: ProjectDesign;
    properties: ApiUpdateDesignProperty[];
    confirmed?: boolean;
}

export interface ApiDesignUpdateResponse {
    projectDesign?: ProjectDesign;
    requiresConfirmation?: RequiresConfirmation;
}

export interface RequiresConfirmation {
    message: TranslationFormat[];
    confirmationType: ConfirmationType;
}

export interface UpdateDesignResult {
    designDetails?: DesignDetails;
    resetAction?: boolean;
}

export const enum ReportPaperSizeId {
    A4 = 1,
    Letter = 2
}

export interface ApiDesignReportGenerateOptions {
    projectDesign: ProjectDesign;
    reportPaperSizeId: ReportPaperSizeId;
    reportTypeId: ReportType;
    calculateResult: CalculateResultReport;
    localization: LocalizationOptions;
    template?: TemplateOptions;
    version: string;
    hiltiOnlineUrl?: string;
    designName: string;
}

export type HtmlReportPaperSize = 'a4' | 'letter';

export interface ApiHtmlReportGenerateOptions {
    html: string;
    reportPaperSize: HtmlReportPaperSize;
}

export interface KernelResultCapacityOfUnreinforcedWall {
    maximumAxialLoad: number;
    maximumInPlaneBendingMoment: number;
    maximumOutOfPlaneBendingMoment: number;
    maximumShearResistanceDiagonalCracking: number;
    maximumShearResistanceStairStepped: number;
}
export interface KernelResultMechanicalParametersRetrofittedWall {
    incrisingFactor: number;
    incrisingFactorNtc: number;
    reinforcedCompressiveStrength: number;
    reinforcedCompressiveStrengthNtc: number;
    reinforcedShearStrengthDiagonalCrack: number;
    reinforcedShearStrengthDiagonalCrackNtc: number;
    reinforcedShearStrengthStairSteppedCrack: number;
    reinforcedShearStrengthStairSteppedCrackNtc: number;
}
export interface KernelResultsCapacityOfRetrofittedWall {
    maximumAxialLoadNtc: number;
    maximumAxialLoadNtcVerified: boolean;
    maximumAxialLoad: number;
    maximumAxialLoadVerified: boolean;
    maximumInPlaneBendingMomentNtc: number;
    maximumInPlaneBendingMomentNtcVerified: boolean;
    maximumInPlaneBendingMoment: number;
    maximumInPlaneBendingMomentVerified: boolean;
    maximumOutOfPlaneBendingMomentNtc: number;
    maximumOutOfPlaneBendingMomentNtcVerified: boolean;
    maximumOutOfPlaneBendingMoment: number;
    maximumOutOfPlaneBendingMomentVerified: boolean;
    maximumShearResistanceDiagonalCrackingNtc: number;
    maximumShearResistanceDiagonalCrackingNtcVerified: boolean;
    maximumShearResistanceDiagonalCracking: number;
    maximumShearResistanceDiagonalCrackingVerified: boolean;
    maximumShearResistanceStairSteppedNtc: number;
    maximumShearResistanceStairSteppedNtcVerified: boolean;
    maximumShearResistanceStairStepped: number;
    maximumShearResistanceStairSteppedVerified: boolean;
    specificWeight: number;
}
export interface KernelResultsIncrementOfCapacityOfRetrofittedWall {
    axialLoadNtc: number;
    axialLoad: number;
    inPlaneBendingMomentNtc: number;
    inPlaneBendingMoment: number;
    outOfPlaneBendingMomentNtc: number;
    outOfPlaneBendingMoment: number;
    shearResistanceDiagonalCrackingNtc: number;
    shearResistanceDiagonalCracking: number;
    shearResistanceStairSteppedNtc: number;
    shearResistanceStairStepped: number;
    specificWeight: number;
}
export interface KernelResultBillOfMaterial {
    numberOfAnchors: number;
    totalLengthOfRebars: number;
    mortarVolume: number;
    numberOfCartridges: number;
    steelMeshArea: number;
    plasterVolume: number;
}
export interface KernelResult {
    kernelResultCapacityOfUnreinforcedWall: KernelResultCapacityOfUnreinforcedWall;
    kernelResultMechanicalParametersRetrofittedWall: KernelResultMechanicalParametersRetrofittedWall;
    kernelResultsCapacityOfRetrofittedWall: KernelResultsCapacityOfRetrofittedWall;
    KernelResultsIncrementOfCapacityOfRetrofittedWall: KernelResultsIncrementOfCapacityOfRetrofittedWall;
    kernelResultBillOfMaterial: KernelResultBillOfMaterial;
    anchorDiameterCalculated: number;
    embedmentDepthCalculated: number;
    extendingProtrudingLengthCalculated: number;
    outsideBendRadiusCalculated: number;
    rebarStrainghtLengthCalculated: number;
    horizontalSpacingCalculated: number;
    verticalSpacingCalculated: number;
    anchorMinEdgeDistanceCalculated: number;
    shiftBetweenAnchorsCalculated: number;
    numberOfAnchorsColumnsCalculated: number;
    numberOfAnchorsRowsCalculated: number;
    numberOfAnchorsPerAreaCalculated: number;
    totalNumberOfAnchorsCalculated: number;
    plasterElaticModulusCalculated: number;
    compressiveStrengthCalculated: number;
    confidenceFactorCalculated: number;
    shearStrengthDiagonalCrackCalculated: number;
    shearStrengthStairSteppedCrackCalculated: number;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CalculateResultReport {
    kernelResult: KernelResult;
}

export interface ApiDesignCalculationOptions {
    projectDesign: ProjectDesign;
}

export type ApiUpdateDesignProperty = {
    [P in WritablePropertyId]: {
        propertyId: P;
        propertyValue: Properties[P];
    };
}[WritablePropertyId];

export interface UpdateAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
    requiresConfirmation?: RequiresConfirmation;
}

export interface CreateAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
}

export interface ConvertAndCalculateResult {
    projectDesign: ProjectDesign;
    designDetails: DesignDetailsData;
    calculationResult: CalculationResult | undefined;
}

export interface UpdateResult {
    designCalculationResult?: UpdateAndCalculateResult;
    resetAction?: boolean;
}

export const enum ConcreteMember {
    Slab = 1,
    Beam = 2,
    Column = 3,
    Wall = 4,
}

export interface OpenDesignResult {
    designDetails: DesignDetails;
    trackingDetails: TrackingDetails;
}

export interface CreateDesignResult {
    designDetails: DesignDetails;
    trackingDetails: TrackingDetails;
}

type OnStateChangeFunction = (this: void, design: Design, state: IDesignStateBase, oldState: IDesignStateBase, stateChange: StateChange) => void;

interface PendingUpdateDesign {
    request: Promise<UpdateDesignResult | undefined>;
    properties: ApiUpdateDesignProperty[];
    cancel: () => void;
}

interface DebounceRequestPending {
    requestId: string;
    request: Promise<unknown>;
}

class DebounceRequest {
    constructor(private guidService: GuidService) { }

    public pendingRequest: DebounceRequestPending = {
        requestId: '',
        request: Promise.resolve()
    };
    public nextRequestId?: string;

    public async request<T>(request: () => Promise<T>, immediateRequest: boolean): Promise<T | undefined> {
        const requestId = this.guidService.new();
        this.nextRequestId = requestId;

        if (!immediateRequest) {
            await new Promise(resolve => setTimeout(resolve, 500));
        }

        await this.pendingRequest.request;

        // skip intermediate document service requests
        if (this.nextRequestId != requestId) {
            return undefined;
        }

        this.pendingRequest = {
            requestId,
            request: Promise.resolve().then(request)
        };

        return await (this.pendingRequest.request as Promise<T>);
    }
}

@Injectable({
    providedIn: 'root'
})
export class DesignService {
    constructor(
        private readonly localizationService: LocalizationService,
        private readonly documentService: DocumentService,
        private readonly userSettingsService: UserSettingsService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly numberService: NumberService,
        private readonly commonCodeListService: CommonCodeListService,
        private readonly dataService: DataService,
        private readonly modalService: ModalService,
        private readonly changesService: ChangesService,
        private readonly coreApiService: CoreApiService,
        private readonly guidService: GuidService,
        private readonly trackingService: TrackingService,
        private readonly featuresVisibilityService: FeatureVisibilityService
    ) {
        this.debounceDocumentService = new DebounceRequest(this.guidService);
        this.debounceDocumentServiceImage = new DebounceRequest(this.guidService);
        this.debounceTracking = new DebounceRequest(this.guidService);
    }

    private readonly debounceDocumentService: DebounceRequest;
    private readonly debounceDocumentServiceImage: DebounceRequest;
    private readonly debounceTracking: DebounceRequest;

    private pendingUpdateDesign?: PendingUpdateDesign;
    private pendingUpdateDesignTemplate?: PendingUpdateDesign;

    /** Default design name in add edit design popup */
    public getNewDesignName() {
        const designName = this.localizationService.getString('MasonryRnf.NewDesign.DefaultName');

        return `${designName} - ${this.localizationService.moment(new Date()).format('ll')}`;
    }

    /**
     * Will do the following:
     * 1. new project design - design-service/design/create
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     * 5. track on design open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async createDesign(options: CreateDesignOptions): Promise<CreateDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceCreateAndCalculate({
            designTypeId: options.designTypeId,
            regionId: options.regionId,

            unitLength: options.unitLength,
            unitStress: options.unitStress,
            unitForce: options.unitForce,
            unitMoment: options.unitMoment,
            unitArea: options.unitArea,
            unitItemsPerArea: options.unitItemsPerArea,
            unitVolume: options.unitVolume,
            unitSpecificWeight: options.unitSpecificWeight
        });

        const documentDesign = await this.documentServiceCreateDesign({
            designName: options.designName,
            designTypeId: options.designTypeId,
            projectDesign: projectDesign,
            projectId: options.projectId,
            regionId: options.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.documentService.findProjectById(documentDesign.projectId).getDisplayName(this.localizationService)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'Blank');

        return {
            designDetails,
            trackingDetails
        };
    }

    /**
     * Will do the following:
     * 1. get design details from project design - design-service/design/details
     * 2. call calculation - calculation-service/calculation/calculate
     * 3. save-and-open document service - document-service/document
     * 4. track on design open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async createDesignFromProjectDesign(options: CreateDesignFromProjectDesignOptions, apiOptions?: ApiOptions): Promise<OpenDesignResult> {
        const {
            designDetails: designDetailsData,
            projectDesign,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(options.projectDesign, apiOptions);

        const documentDesign = await this.documentServiceCreateDesign({
            designName: options.designName,
            designTypeId: designDetailsData.designTypeId,
            projectId: options.projectId,
            regionId: designDetailsData.regionId,
            projectDesign
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.documentService.findProjectById(documentDesign.projectId).getDisplayName(this.localizationService)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'ImportedProfis3File');

        return {
            designDetails,
            trackingDetails
        };
    }

    /**
     * Returns undefined when we have other pending updates.
     *
     * Will do the following:
     * 1. update project design - design-service/design/update
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     * 5. track on design change
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Undefined values in properties are not updated. Null values are updated.
     */
    public async updateDesign(options: UpdateDesignOptions): Promise<UpdateDesignResult | undefined> {
        let properties = [
            ...options.properties
        ];

        // cancel existing update
        if (this.pendingUpdateDesign != null) {
            properties = [
                ...this.pendingUpdateDesign.properties,
                ...properties,
            ];

            this.pendingUpdateDesign.cancel();
        }

        const cancellationTokenSource = new CancellationTokenSource();
        const apiOptions: ApiOptions = {
            cancellationToken: cancellationTokenSource.token
        };

        const pendingUpdateDesign = this.pendingUpdateDesign = {
            properties,
            cancel: () => cancellationTokenSource.cancel(),
            request: Promise.resolve().then(async (): Promise<UpdateDesignResult | undefined> => {
                try {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    const updateResult = await this.coreServiceUpdateAndCalculate({
                        projectDesign: options.projectDesign,
                        properties
                    }, apiOptions);

                    if (updateResult.resetAction) {
                        return {
                            resetAction: updateResult.resetAction
                        };
                    }

                    const projectDesign = updateResult.designCalculationResult!.projectDesign;
                    const designDetailsData = updateResult.designCalculationResult!.designDetails;
                    const calculationResult = updateResult.designCalculationResult!.calculationResult;

                    const designDetails = this.createDesignDetails({
                        projectDesign,
                        designDetailsData,
                        calculationResult,

                        designId: options.designId,
                        designName: options.designName,
                        projectId: options.projectId,
                        projectName: this.documentService.findProjectById(options.projectId).getDisplayName(this.localizationService)
                    });

                    // run in the background
                    const documentServiceUpdateDesignPromise = this.documentServiceUpdateDesign({
                        designId: options.designId,
                        designName: options.designName,
                        designTypeId: designDetails.designTypeId,
                        projectDesign: projectDesign,
                        projectId: options.projectId,
                        regionId: designDetails.regionId,

                        immediateRequest: options.immediateRequest
                    });

                    const trackingPromise = this.trackOnDesignOrTemplateChange({
                        designDetails,
                        trackingDetails: options.trackingDetails,

                        immediateRequest: options.immediateRequest
                    });

                    // true: call document service immediately and return a Promise that awaits the document service request
                    // false: call document service in the background with debounce and return a Promise that does NOT await the document service
                    if (options.immediateRequest) {
                        await trackingPromise;

                        const documentServiceUpdateDesign = await documentServiceUpdateDesignPromise;
                        if (documentServiceUpdateDesign == null) {
                            return undefined;
                        }
                    }

                    return {
                        designDetails
                    };
                }
                catch (error) {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    throw error;
                }
                finally {
                    if (this.pendingUpdateDesign === pendingUpdateDesign) {
                        this.pendingUpdateDesign = undefined;
                    }
                }
            })
        };

        return await this.pendingUpdateDesign.request;
    }

    public async updateDesignOrDesignTemplate(options: UpdateDesignOrDesignTemplateOptions): Promise<UpdateDesignResult | undefined> {
        let result: UpdateDesignResult | undefined;

        if (options.designId != null && options.designName != null && options.projectId != null) {
            result = await this.updateDesign({
                designId: options.designId,
                designName: options.designName,
                projectId: options.projectId,

                projectDesign: options.projectDesign,
                properties: options.properties,

                trackingDetails: options.trackingDetails,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null && options.templateName != null) {
            result = await this.updateDesignTemplate({
                templateId: options.templateId,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId,

                projectDesign: options.projectDesign,
                properties: options.properties,

                trackingDetails: options.trackingDetails,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId or templateId');
        }

        return result;
    }

    /**
     * Will do the following:
     * 1. convert to latest project design - design-service/design/convert
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     * 5. track on design open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     */
    public async updateDesignFromProjectDesign(options: UpdateDesignFromProjectDesignOptions, apiOptions?: ApiOptions): Promise<OpenDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(options.projectDesign, apiOptions);

        const documentDesign = (await this.documentServiceUpdateDesign({
            designId: options.designId,
            designName: options.designName,
            designTypeId: designDetailsData.designTypeId,
            projectDesign: projectDesign,
            projectId: options.projectId,
            regionId: designDetailsData.regionId,

            immediateRequest: options.immediateRequest
        }))!;

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.documentService.findProjectById(documentDesign.projectId).getDisplayName(this.localizationService)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'ImportedProfis3File');

        return {
            designDetails,
            trackingDetails
        };
    }

    /**
     * Will do the following:
     * 1. update document image - document-service/documentcontent/UploadDocumentImage
     */
    public async updateDesignImage(options: UpdateDesignImageOptions) {
        return await this.debounceDocumentServiceImage.request(async () => {
            await this.documentService.updateDesignThumbnailImage(options.designId, options.base64Image, false);
        },
            options.immediateRequest);
    }

    public async updateDesignImageOrTemplateDesignImage(options: UpdateDesignImageOrTemplateDesignImageOptions) {
        if (options.designId != null) {
            await this.updateDesignImage({
                designId: options.designId,
                base64Image: options.base64Image,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null) {
            await this.updateDesignTemplateImage({
                templateId: options.templateId,
                base64Image: options.base64Image,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId or templateId');
        }
    }

    /**
     * Will do the following:
     * 1. update document image - document-service/DocumentDesignTemplate/ThumbnailUpdate
     */
    public async updateDesignTemplateImage(options: UpdateDesignTemplateImageOptions) {
        return await this.debounceDocumentServiceImage.request(async () => {
            await this.designTemplateService.updateDesignThumbnailImage(options.templateId, options.base64Image, false);
        },
            options.immediateRequest);
    }

    /**
     * Will do the following:
     * 1. get document service - document-service/documentContent/GetExclusive
     * 2. convert to latest project design - design-service/design/update
     * 3. get design details from project design - design-service/design/details
     * 4. call calculation - calculation-service/calculation/calculate
     * 5. update document service - document-service/documentcontent
     * 6. track on design open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design on document service is opened/locked.
     *
     * closeDesign MUST BE CALLED WHEN DONE WORKING WITH DESIGN
     */
    public async openDesign(options: OpenDesignOptions): Promise<OpenDesignResult> {
        try {
            const documentDesign = this.documentService.findDesignById(options.designId);
            const projectDesign = await this.documentService.openDesignExclusive<ProjectDesign>(documentDesign);

            const {
                projectDesign: updatedProjectDesign,
                designDetails: designDetailsData,
                calculationResult
            } = await this.coreServiceConvertAndCalculate(projectDesign);

            // TODO FILIP: do we only update if we have changes from designServiceUpdateDesign?
            const updatedDocumentDesign = (await this.documentServiceUpdateDesign({
                designId: options.designId,
                designName: documentDesign.designName,
                designTypeId: designDetailsData.designTypeId,
                projectDesign: updatedProjectDesign,
                projectId: documentDesign.projectId,
                regionId: designDetailsData.regionId,

                immediateRequest: true
            }))!;

            const designDetails = this.createDesignDetails({
                projectDesign: updatedProjectDesign,
                designDetailsData,
                calculationResult,

                designId: updatedDocumentDesign.id,
                designName: updatedDocumentDesign.designName,
                projectId: documentDesign.projectId,
                projectName: this.documentService.findProjectById(documentDesign.projectId).getDisplayName(this.localizationService)
            });

            // tracking open
            const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'OpenExisting');

            return {
                designDetails,
                trackingDetails
            };
        }
        catch (error) {
            if (error instanceof CantOpenDesignBecauseLockedByOtherUser) {
                this.modalService.openAlertWarning(this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Title'),
                    formatKeyValue(this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Alerts.CannotOpenInUseBy.Description'), {
                        user: error.username ?? ''
                    })
                );
            }

            throw error;
        }
    }

    /**
     * Will do the following:
     * 1. close document on document service
     * 2. track on design close
     */
    public async closeDesign(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        // document close
        await this.documentService.publish(designDetails.designId!);

        // tracking close
        await this.trackingService.trackOnDesignClose(designDetails, trackingDetails);
    }

    public createPeDesignObject(designDetails: DesignDetails, trackingDetails: TrackingDetails): Design {
        const metaData = {};
        const designType = {};
        const region = {};
        const onStateChangedFunctions: OnStateChangeFunction[] = [];

        const peDesignObject = {
            metaData,
            designType,
            region,
            onStateChangedFunctions,
            designStandard: {
                // TODO FILIP: why does pe-ui need this?
                id: 0
            },
            properties: {
                get: (propertyId: PropertyId): IProperty => {
                    // pe-ui is calling this function even when we don't set UIProperty
                    if (propertyId == null) {
                        return {
                            disabled: false,
                            hidden: false,
                            itemsTexts: {}
                        };
                    }

                    const designDetails = (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails;
                    const propertyDetails = designDetails.propertyDetails[propertyId];

                    // missing property details
                    if (propertyDetails == null) {
                        console.warn(`Missing designDetails.propertyDetails for '${propertyId}'`);

                        return {
                            disabled: false,
                            hidden: false,
                            itemsTexts: {}
                        };
                    }

                    return {
                        disabled: propertyDetails.disabled === true,
                        hidden: propertyDetails.hidden === true,
                        allowedValues: propertyDetails.allowedValues,
                        min: propertyDetails.minValue,
                        max: propertyDetails.maxValue,
                        itemsTexts: {}
                    };
                }
            },
            onStateChanged: (fn: OnStateChangeFunction) => {
                onStateChangedFunctions.push(fn);
            },
            onAllowedValuesChanged: () => {
                // not needed
            },
            on: () => {
                // not needed
            },
            dispose: () => {
                onStateChangedFunctions.splice(0, onStateChangedFunctions.length);
            },
            trackingDetails
        } as unknown as InternalDesign;

        // define as getters so we don't need to update them
        Object.defineProperties(peDesignObject, {
            id: {
                get: () => peDesignObject.designDetails.designId
            },
            projectId: {
                get: () => peDesignObject.designDetails.projectId
            },
            designName: {
                get: () => peDesignObject.designDetails.designName
            },
            templateId: {
                get: () => peDesignObject.designDetails.templateId
            },
            isTemplate: {
                get: () => peDesignObject.designDetails.isTemplate
            },
            templateName: {
                get: () => peDesignObject.designDetails.templateName,
                set: () => {
                    // TODO TEAM: fix common - pe-ui is trying to override templateName on design for some strange reason
                }
            },
            projectDesign: {
                get: () => peDesignObject.designDetails.projectDesign
            },
            designTypeId: {
                get: () => peDesignObject.designDetails.designTypeId
            },
            regionId: {
                get: () => peDesignObject.designDetails.regionId
            },

            // needed by unit.service
            unitLength: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitLength
            },
            unitLengthLarge: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitLength
            },
            unitArea: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitArea
            },
            unitStress: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitStress
            },
            unitStressSmall: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitStress
            },
            unitForce: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitForce
            },
            unitMoment: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitMoment
            },
            unitTemperature: {
                get: () => 0
            },
            unitForcePerLength: {
                get: () => 0
            },
            unitMomentPerLength: {
                get: () => 0
            },
            unitDensity: {
                get: () => 0
            },
            unitAreaPerLength: {
                get: () => 0
            },
            unitItemsPerArea: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitItemsPerArea
            },
            unitVolume: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitVolume
            },
            unitSpecificWeight: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.properties.unitSpecificWeight
            },
        });

        Object.defineProperties(metaData, {
            region: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.regionId
            },
            designType: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.designTypeId
            },
            standard: {
                get: () => 0
            },
        });

        Object.defineProperties(designType, {
            id: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.designTypeId
            },
        });

        Object.defineProperties(region, {
            id: {
                get: () => (peDesignObject as unknown as { designDetails: DesignDetails }).designDetails.regionId
            },
        });

        this.updatePeDesignObject(peDesignObject, designDetails);
        return peDesignObject;
    }


    /**
     * Will do the following:
     * 1. new project design - design-service/design/create
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     *
     * closeDesignTemplate MUST BE CALLED WHEN DONE WORKING WITH DESIGN TEMPLATE
     */
    public async createDesignTemplate(options: CreateDesignTemplateOptions): Promise<CreateDesignResult> {
        const {
            projectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceCreateAndCalculate(options);

        const documentDesign = await this.documentServiceCreateDesignTemplate({
            templateName: options.templateName,
            templateProjectId: options.templateProjectId,
            designTypeId: options.designTypeId,
            projectDesign: projectDesign,
            regionId: options.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign,
            designDetailsData,
            calculationResult,

            templateId: documentDesign.DesignTemplateDocumentId,
            templateName: documentDesign.DesignTemplateName,
            templateProjectId: documentDesign.templateFolderId,
            templateProjectName: this.getTemplateProjectName(documentDesign.templateFolderId)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnTemplateOpen(designDetails, 'TemplateEdit');

        return {
            designDetails,
            trackingDetails
        };
    }

    public async createDesignOrDesignTemplate(options: CreateDesignOrDesignTemplateOptions): Promise<CreateDesignResult> {
        if (options.designName != null && options.projectId != null) {
            return await this.createDesign({
                ...options,
                designName: options.designName,
                projectId: options.projectId
            });
        }
        else if (options.templateName != null) {
            return await this.createDesignTemplate({
                ...options,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId
            });
        }
        else {
            throw new Error('Must set designName/projectId or templateName/templateProjectId');
        }
    }

    public updatePeDesignObject(peDesignObject: Design, designDetails: DesignDetails) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (peDesignObject as any).designDetails = designDetails;

        // set change detection
        const model = peDesignObject.model = {} as Record<string, unknown>;
        for (const propertyId in designDetails.properties) {
            model[propertyId as unknown as number] = designDetails.properties[propertyId as PropertyId];
        }

        const modelChanges = peDesignObject.modelChanges ??= new TrackChanges({
            collapse: true,
            ignoreUndefined: true,
            shallowChanges: true,
            changesService: this.changesService,
            logger: {} as unknown as LoggerServiceBase
        });

        modelChanges.set(model);
        modelChanges.clear();

        // trigger state changed event for menu
        const onStateChangedFunctions = (peDesignObject as unknown as { onStateChangedFunctions: OnStateChangeFunction[] }).onStateChangedFunctions;
        for (const onStateChangedFunction of onStateChangedFunctions) {
            onStateChangedFunction(peDesignObject, {
                model,
                properties: peDesignObject.properties
            } as IDesignStateBase, undefined as unknown as IDesignStateBase, undefined as unknown as StateChange);
        }
    }

    /**
     * Will do the following:
     * 1. get template document service - document-service/DocumentDesignTemplate/Get
     * 2. convert to latest project design - design-service/design/update
     * 3. get design details from project design - design-service/design/details
     * 4. call calculation - calculation-service/calculation/calculate
     * 5. update template document service - document-service/DocumentDesignTemplate/Update
     * 6. track on design template open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     */
    public async openDesignTemplate(options: OpenDesignTemplateOptions): Promise<OpenDesignResult> {
        const documentDesignTemplate = await this.designTemplateService.getById(options.designTemplateId);
        const projectDesign: ProjectDesign = JSON.parse(documentDesignTemplate.ProjectDesign);

        const {
            projectDesign: updatedProjectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(projectDesign);

        // TODO FILIP: do we only update if we have changes from designServiceUpdateDesign?
        const updatedDocumentDesignTemplate = (await this.documentServiceUpdateDesignTemplate({
            designTypeId: designDetailsData.designTypeId,
            projectDesign: updatedProjectDesign,
            regionId: designDetailsData.regionId,
            templateId: options.designTemplateId,
            templateProjectId: documentDesignTemplate.templateFolderId!,
            templateName: documentDesignTemplate.DesignTemplateName,

            immediateRequest: true
        }))!;


        const designDetails = this.createDesignDetails({
            projectDesign: updatedProjectDesign,
            designDetailsData,
            calculationResult,

            templateId: updatedDocumentDesignTemplate.DesignTemplateDocumentId,
            templateName: updatedDocumentDesignTemplate.DesignTemplateName
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnTemplateOpen(designDetails, 'TemplateEdit');

        return {
            designDetails,
            trackingDetails
        };
    }

    /**
     * Will do the following:
     * 1. track on design template close
     */
    public async closeDesignTemplate(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        // tracking close
        await this.trackingService.trackOnTemplateClose(designDetails, trackingDetails);
    }

    public async closeDesignOrDesignTemplate(designDetails: DesignDetails, trackingDetails: TrackingDetails) {
        if (designDetails.isTemplate) {
            await this.closeDesignTemplate(designDetails, trackingDetails);
        }
        else {
            await this.closeDesign(designDetails, trackingDetails);
        }
    }

    /**
     * Will do the following:
     * 1. new project design - design-service/design/create
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. save-and-open document service - document-service/document
     * 5. track on design open
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Design created on document service is opened/locked.
     */
    public async createDesignFromTemplate(options: CreateDesignFromTemplateOptions): Promise<OpenDesignResult> {
        const template = await this.designTemplateService.getById(options.templateId);
        const projectDesign: ProjectDesign = JSON.parse(template.ProjectDesign);

        const {
            projectDesign: updatedProjectDesign,
            designDetails: designDetailsData,
            calculationResult
        } = await this.coreServiceConvertAndCalculate(projectDesign);

        const documentDesign = await this.documentServiceCreateDesign({
            designName: this.getTemplateDesignName(options.designName),
            designTypeId: designDetailsData.designTypeId,
            projectDesign: updatedProjectDesign,
            projectId: options.projectId ?? this.documentService.draftsProject.id!,
            regionId: designDetailsData.regionId
        });

        const designDetails = this.createDesignDetails({
            projectDesign: updatedProjectDesign,
            designDetailsData,
            calculationResult,

            designId: documentDesign.id,
            designName: documentDesign.designName,
            projectId: documentDesign.projectId,
            projectName: this.documentService.findProjectById(documentDesign.projectId).getDisplayName(this.localizationService)
        });

        // tracking open
        const trackingDetails = await this.trackingService.trackOnDesignOpen(designDetails, 'BlankFromTemplate');

        return {
            designDetails,
            trackingDetails
        };
    }
    private getTemplateDesignName(designName: string | undefined) {
        if (designName != undefined)
            return designName;

        const templateDesignName = this.getNewDesignName();
        return this.documentService.createUniqueName(templateDesignName, Object.values(this.documentService.draftsProject?.designs ?? []).map((item) => item.designName));
    }

    /**
     * Returns undefined when we have other pending updates.
     *
     * Will do the following:
     * 1. update project design - design-service/design/update
     * 2. get design details from project design - design-service/design/details
     * 3. call calculation - calculation-service/calculation/calculate
     * 4. (async - check options.immediateRequest) update document service - document-service/documentcontent
     *
     * If calculation fails we return design details with calculation results that only contain an error scopecheck.
     *
     * Undefined values in properties are not updated. Null values are updated.
     */
    public async updateDesignTemplate(options: UpdateDesignTemplateOptions): Promise<UpdateDesignResult | undefined> {
        let properties = [
            ...options.properties
        ];

        // cancel existing update
        if (this.pendingUpdateDesignTemplate != null) {
            properties = [
                ...this.pendingUpdateDesignTemplate.properties,
                ...properties,
            ];

            this.pendingUpdateDesignTemplate.cancel();
        }

        const cancellationTokenSource = new CancellationTokenSource();
        const apiOptions: ApiOptions = {
            cancellationToken: cancellationTokenSource.token
        };

        const pendingUpdateDesignTemplate = this.pendingUpdateDesignTemplate = {
            properties,
            cancel: () => cancellationTokenSource.cancel(),
            request: Promise.resolve().then(async (): Promise<UpdateDesignResult | undefined> => {
                try {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    const updateResult = await this.coreServiceUpdateAndCalculate({
                        projectDesign: options.projectDesign,
                        properties
                    }, apiOptions);

                    if (updateResult.resetAction) {
                        return {
                            resetAction: updateResult.resetAction
                        };
                    }

                    const projectDesign = updateResult.designCalculationResult!.projectDesign;
                    const designDetailsData = updateResult.designCalculationResult!.designDetails;
                    const calculationResult = updateResult.designCalculationResult!.calculationResult;

                    const designDetails = this.createDesignDetails({
                        projectDesign,
                        designDetailsData,
                        calculationResult,

                        templateId: options.templateId,
                        templateName: options.templateName
                    });

                    // run in the background
                    const documentServiceUpdateDesignTemplatePromise = this.documentServiceUpdateDesignTemplate({
                        templateId: options.templateId,
                        templateName: options.templateName,
                        templateProjectId: options.templateProjectId,
                        designTypeId: designDetails.designTypeId,
                        projectDesign: projectDesign,
                        regionId: designDetails.regionId,

                        immediateRequest: options.immediateRequest
                    });

                    const trackingPromise = this.trackOnDesignOrTemplateChange({
                        designDetails,
                        trackingDetails: options.trackingDetails,

                        immediateRequest: options.immediateRequest
                    });

                    // true: call document service immediately and return a Promise that awaits the document service request
                    // false: call document service in the background with debounce and return a Promise that does NOT await the document service request
                    if (options.immediateRequest) {
                        await trackingPromise;

                        const documentServiceUpdateDesignTemplate = await documentServiceUpdateDesignTemplatePromise;
                        if (documentServiceUpdateDesignTemplate == null) {
                            return undefined;
                        }
                    }

                    return {
                        designDetails
                    };
                }
                catch (error) {
                    if (cancellationTokenSource.isCanceled) {
                        return undefined;
                    }

                    throw error;
                }
                finally {
                    if (this.pendingUpdateDesignTemplate === pendingUpdateDesignTemplate) {
                        this.pendingUpdateDesignTemplate = undefined;
                    }
                }
            })
        };

        return await this.pendingUpdateDesignTemplate.request;
    }

    /** design list box for templates */
    public toDisplayDesignTemplate(template: DesignTemplateEntity, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const commonRegion = this.getCommonRegionById(template.RegionId)!;

        return {
            id: template.DesignTemplateDocumentId,
            name: template.DesignTemplateName,
            created: template.DateCreate,
            createdDisplay: () => this.localizationService.moment(template.DateCreate).format(DefaultDateFormat),
            projectName: this.localizationService.getString(designTypesById[template.DesignTypeId as DesignTypeId].nameKey),
            rawProject: undefined,
            projectId: undefined as unknown as string,
            region: commonRegion,
            productName: '',
            approvalNumber: '',
            designType: template.DesignTypeId,
            regionDesignStandardApprovalNumber: this.createRegionDesignStandardApprovalNumber(template.RegionId),
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail?.(template.DesignTemplateDocumentId),
            displayDesignType: DisplayDesignType.template,
            regionText: this.createRegionText(commonRegion),
            designStandardTextApprovalNumberText: this.designStandardTextApprovalNumberText()
        };
    }

    /** design list box */
    public toDisplayDesign(design: IDesignListItem, designTypeImage: string, getDesignThumbnail?: (designId: string) => string): IDisplayDesign {
        const commonRegion = this.getCommonRegionById(design.metaData?.region)!;

        const designType = design.metaData?.designType;
        const parentProject = this.documentService.findProjectById(design.projectId);
        return {
            id: design.id,
            name: design.designName,
            created: design.createDate,
            createdDisplay: () => this.localizationService.moment(design.createDate).format(DefaultDateFormat),
            rawProject: parentProject,
            projectId: design.projectId,
            productName: '',
            designType,
            approvalNumber: '',
            region: commonRegion,
            image: getSpriteAsIconStyle(designTypeImage),
            thumbnail: getDesignThumbnail?.(design.id),
            displayDesignType: DisplayDesignType.design,
            regionText: this.createRegionText(commonRegion),
            regionDesignStandardApprovalNumber: this.createRegionDesignStandardApprovalNumber(commonRegion.id),
            designStandardTextApprovalNumberText: this.designStandardTextApprovalNumberText(),
            isFavorite: design.isFavorite ?? false,
            isSharedByMe: design.isSharedByMe ?? false
        };
    }

    /** Create description for quick start buttons and design list box */
    public createRegionDesignStandardApprovalNumber(regionId: number) {
        const region = this.dataService.regionsById[regionId];
        const regionName = region?.nameKey != null ? this.localizationService.getString(region.nameKey) : 'Unknown';

        return regionName;
    }

    /** Create description for design list list view */
    public designStandardTextApprovalNumberText() {
        return '';
    }

    /** Check if calculation is OK */
    public isCalculationValid(calculationResult: CalculationResult | undefined): boolean {
        return calculationResult?.calculationStatus == CalculationStatus.OK;
    }


    public getTemplateProjectName(templateProjectId: string | undefined): string {
        // templateProjectId is null when template project is root
        if (templateProjectId == null) {
            return this.localizationService.getString('Agito.Hilti.Profis3.Main.TemplateProjectName');
        }

        return this.designTemplateService.findTemplateFolderById(templateProjectId).name;
    }

    private getCommonRegionById(regionId: number | null) {
        const regionCodeList = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private createRegionText(region?: CommonRegion) {
        if (region?.id == SpecialRegion.Default) {
            region = this.getCommonRegionById(this.userSettingsService.settings.application.general.regionId.value);
        }

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        const text = region?.getTranslatedNameText(codeListDeps) ?? '';

        return text == '' ? this.localizationService.getString('Agito.Hilti.Profis3.ProjectAndDesing.Main.DesignMetaData.Unknown') : text;
    }

    private async coreServiceCreateAndCalculate(options: DesignServiceCreateDesignOptions, apiOptions?: ApiOptions): Promise<CreateAndCalculateResult> {
        const designCreateRequest: ApiDesignCreateRequest = {
            designTypeId: options.designTypeId,
            regionId: options.regionId,

            unitLength: options.unitLength,
            unitStress: options.unitStress,
            unitForce: options.unitForce,
            unitMoment: options.unitMoment,
            unitArea: options.unitArea,
            unitItemsPerArea: options.unitItemsPerArea,
            unitVolume: options.unitVolume,
            unitSpecificWeight: options.unitSpecificWeight
        };

        return await this.coreApiService.api.core.createAndCalculate(designCreateRequest, apiOptions);
    }

    private async coreServiceUpdateAndCalculate(options: DesignServiceUpdateDesignOptions, apiOptions?: ApiOptions): Promise<UpdateResult> {
        const designUpdateRequest: DesignUpdateRequest = {
            projectDesign: options.projectDesign,
            properties: options.properties
        };

        return await this.coreApiService.api.core.updateAndCalculate(designUpdateRequest, apiOptions);
    }

    private async coreServiceConvertAndCalculate(projectDesign: ProjectDesign, apiOptions?: ApiOptions): Promise<ConvertAndCalculateResult> {
        return await this.coreApiService.api.core.convertAndCalculate(projectDesign, apiOptions);
    }

    public async documentServiceCreateDesign(options: DocumentServiceCreateDesignOptions): Promise<IDesignListItem> {
        const design = {
            designName: options.designName,
            metaData: {
                region: options.regionId,
                designType: options.designTypeId
            },
            projectDesign: options.projectDesign
        } as Design;

        return await this.documentService.addDesignCommon(options.projectId, design, true, true);
    }

    private createDesignDetails(options: CreateDesignDetailsOptions): DesignDetails {
        // merge propertyDetails with appPropertyDetails
        const propertyDetails = { ...options.designDetailsData.propertyDetails };
        for (const _propertyId in propertyDetails) {
            const propertyId = _propertyId as PropertyId;
            const propertyDetail = propertyDetails[propertyId] = {
                ...propertyDetails[propertyId]
            };

            const appPropertyDetail = this.dataService.getPropertyDetail(options.designDetailsData.regionId, propertyId as ApiAppPropertyId);
            if (appPropertyDetail != null) {
                propertyDetail.allowedValues ??= appPropertyDetail.allowedValues;
                propertyDetail.defaultValue ??= appPropertyDetail.defaultValue;
                propertyDetail.disabled ??= appPropertyDetail.disabled;
                propertyDetail.hidden ??= appPropertyDetail.hidden;
                propertyDetail.maxValue ??= appPropertyDetail.maxValue;
                propertyDetail.minValue ??= appPropertyDetail.minValue;
                propertyDetail.precision ??= appPropertyDetail.precision;
            }
        }

        const designDetails: DesignDetails = {
            properties: options.designDetailsData.properties,
            propertyDetails: propertyDetails,
            regionId: options.designDetailsData.regionId,
            designTypeId: options.designDetailsData.designTypeId,
            projectDesign: options.projectDesign,
            isTemplate: options.templateId != null,
            projectId: options.projectId,
            projectName: options.projectName,
            region: this.dataService.regionsById[options.designDetailsData.regionId],
            commonRegion: (this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[]).find(x => x.id == options.designDetailsData.regionId) as CommonRegion,
            designId: options.designId,
            designName: options.designName,
            templateId: options.templateId,
            templateName: options.templateName,
            templateProjectId: options.templateProjectId,
            templateProjectName: options.templateProjectName,
            designType: designTypesById[options.designDetailsData.designTypeId],
            calculationResult: options.calculationResult
        };

        return designDetails;
    }

    /**
     * Safe to call again before the first request is done. Calls are truncated after first call to not do multiple requests at the same time.
     */
    public async documentServiceUpdateDesign(options: DocumentServiceUpdateDesignOptions): Promise<IDesignListItem | undefined> {
        const design = {
            id: options.designId,
            designName: options.designName,
            projectId: options.projectId,
            metaData: {
                region: options.regionId,
                designType: options.designTypeId
            },
            projectDesign: options.projectDesign
        } as Design;

        return await this.debounceDocumentService.request(async () => {
            await this.documentService.updateDesignWithNewContentCommon(design, undefined, false, false);
            return this.documentService.findDesignById(options.designId);
        }, options.immediateRequest);
    }


    public async documentServiceCreateDesignTemplate(options: DocumentServiceCreateDesignTemplateOptions): Promise<DesignTemplateEntity> {
        const template: IDesignTemplateDocument = {
            designTypeId: options.designTypeId,
            regionId: options.regionId,
            templateName: options.templateName,
            templateFolderId: options.templateProjectId,
            projectDesign: JSON.stringify(options.projectDesign),
            // TODO FILIP: this should be a generic object that you can pass anything inside (similar to design.metaData)
            designStandardId: undefined as unknown as number,
            anchorName: '',
            approvalNumber: ''
        };

        const templateId = await this.designTemplateService.create(template);
        return this.designTemplateService.findById(templateId);
    }

    public async documentServiceUpdateDesignTemplate(options: DocumentServiceUpdateDesignTemplateOptions): Promise<DesignTemplateEntity | undefined> {
        const template: IDesignTemplateDocument = {
            designTemplateDocumentId: options.templateId,
            designTypeId: options.designTypeId,
            regionId: options.regionId,
            templateName: options.templateName,
            projectDesign: JSON.stringify(options.projectDesign),
            // TODO FILIP: this should be a generic object that you can pass anything inside (similar to design.metaData)
            designStandardId: 0,
            anchorName: '',
            approvalNumber: ''
        };

        return await this.debounceDocumentService.request(async () => {
            await this.designTemplateService.update(template);
            return this.designTemplateService.findById(options.templateId);
        }, options.immediateRequest);
    }

    public async trackOnDesignOrTemplateChange(options: TrackOnDesignChangeOptions): Promise<void> {
        return await this.debounceTracking.request(async () => {
            if (options.designDetails.isTemplate) {
                await this.trackingService.trackOnTemplateChange(options.designDetails, options.trackingDetails);
            }
            else {
                await this.trackingService.trackOnDesignChange(options.designDetails, options.trackingDetails);
            }
        }, options.immediateRequest);
    }

    public async documentServiceUpdateDesignOrDesignTemplate(options: DocumentServiceUpdateDesignOrDesignTemplateOptions): Promise<IDesignListItem | DesignTemplateEntity | undefined> {
        if (options.designId != null && options.designName != null && options.projectId != null) {
            return await this.documentServiceUpdateDesign({
                designId: options.designId,
                designName: options.designName,
                projectId: options.projectId,
                projectDesign: options.projectDesign,
                designTypeId: options.designTypeId,
                regionId: options.regionId,

                immediateRequest: options.immediateRequest
            });
        }
        else if (options.templateId != null && options.templateName != null) {
            return await this.documentServiceUpdateDesignTemplate({
                templateId: options.templateId,
                templateName: options.templateName,
                templateProjectId: options.templateProjectId,
                projectDesign: options.projectDesign,
                designTypeId: options.designTypeId,
                regionId: options.regionId,

                immediateRequest: options.immediateRequest
            });
        }
        else {
            throw new Error('Must set designId or templateId');
        }
    }
}
